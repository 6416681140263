<!-- eslint-disable vue/no-v-model-argument -->
<!-- eslint-disable vue/no-deprecated-v-bind-sync -->
<template>
  <v-data-table
    :page.sync="currentpage"
    :options.sync="optionsData"
    :headers="headers"
    :items="evacuees"
    :sort-by="sortBy"
    :sort-desc="sortByDesc"
    :loading="userloadingState"
    :search="search"
    multi-sort
    class="elevation-1"
    :footer-props="{
      showFirstLastPage: true,
      itemsPerPageText: 'Elemente pro Seite:',
      itemsPerPageAllText: 'Alle',
    }"
  >
    <template #[`footer.page-text`]="items">
      {{ items.pageStart }} - {{ items.pageStop }} / {{ items.itemsLength }}
    </template>
    <template #top>
      <v-toolbar flat dense short>
        <v-toolbar-title>{{ name }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Suche"
          single-line
          hide-details
          clearable
          @click="searchFocussed(true)"
          @blur="searchFocussed(false)"
        ></v-text-field>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-card class="mx-auto" min-width="400">
          <v-slider
            v-model="autoUpdateTime"
            class="mx-4"
            :max="120"
            :min="0"
            :rules="rules"
            label="Sekunden"
            inverse-label
            thumb-label
            hide-details="auto"
            dense
            @change="setTimer(autoUpdateTime)"
          ></v-slider></v-card
        ><v-spacer></v-spacer>
        <v-card class="mx-auto"
          ><v-switch
            v-model="showEvacuatedState"
            dense
            hide-details="auto"
            :label="`Evakuierte anzeigen`"
            @change="toggleShowEvacuated($event)"
          ></v-switch
        ></v-card>
        <v-spacer></v-spacer>
        <v-btn color="primary" x-small fab @click="initialize">
          <v-icon medium>mdi-reload</v-icon>
        </v-btn>
        <v-divider vertical inset></v-divider>
      </v-toolbar>
    </template>
    <template #[`item.actions`]="{ item }">
      <template v-if="!item.signedout">
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-btn
              fab
              dark
              small
              color="orange"
              @click.stop="openManEvacDialog(item)"
              v-on="on"
              ><v-icon dark> mdi-exit-run </v-icon></v-btn
            > </template
          ><span>Manuell evakuieren</span>
        </v-tooltip></template
      >
    </template>
    <template #[`item.signedout`]="{ item }">
      <template v-if="item.signedout">
        <v-icon color="success">mdi-account-arrow-left</v-icon>
        <span class="success--text">{{
          convertTimeFormat(item.signedoutat)
        }}</span>
        <span v-if="item.signedoutuserid != 0" class="success--text">
          durch Benutzer {{ item.signedoutusername }} ({{
            item.signedoutuserid
          }})</span
        ></template
      >
      <template v-else>
        <v-icon v-model="item.signedout" color="error"
          >mdi-account-question</v-icon
        ></template
      >
    </template>
    <template #[`item.visitor`]="{ item }">
      <v-icon v-if="item.visitor == 1" color="success"
        >mdi-check-outline</v-icon
      >
    </template>
    <template #no-data>
      <v-btn color="primary" @click="initialize">Laden</v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Swal from "sweetalert2";
const aerraudio = new Audio("/assets/audio/sound_err.mp3");
const succaudio = new Audio("/assets/audio/sound_succ.mp3");
export default {
  name: "EvacueesTable",
  props: {
    timeractive: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:page"],
  data: () => ({
    name: "Evakuierung",
    search: "",
    currentpage: 1,
    prevpage: null,
    optionsData: { page: 1 },
    headers: [
      { text: "ID", align: "start", value: "ID" },
      { text: "Besucher", value: "visitor" },
      { text: "Vorname", value: "firstname" },
      { text: "Nachname", value: "lastname" },
      { text: "Abteilung", value: "department" },
      { text: "Evakuiert", value: "signedout" },
      { text: "Aktionen", value: "actions", sortable: false },
    ],
    sortBy: ["signedout", "ID"],
    sortByDesc: [false, false],
    timer: "",
    autoUpdateTime: 10,
    rules: [(v) => v > 0 || "Deaktiviert"],
    showEvacuatedState: false,
    error: false,
  }),
  computed: {
    ...mapState({
      currentUser: (state) => state.auth.user,
      showEvacuated: (state) => state.evacuees.showEvacuated,
    }),
    ...mapGetters("evacuees", {
      userloadingState: "loadingState",
      evacuees: "evacuees",
    }),
    errorState: {
      get() {
        return this.$store.state.evacuees.error;
      },
      set(value) {
        this.$store.dispatch("setErrorState", value);
      },
    },
  },
  mounted() {
    this.initialize();
    this.setTimer(this.autoUpdateTime);
  },
  methods: {
    searchFocussed(value) {
      switch (value) {
        case true:
          this.prevpage = this.currentpage;
          this.currentpage = 1;
          this.optionsData = { page: 1 };
          break;
        case false:
          this.currentpage = this.prevpage;
          this.optionsData = { page: this.prevpage };
          break;
        default:
          break;
      }
    },
    toggleShowEvacuated(event) {
      if (event) {
        this.$store.dispatch("evacuees/enableShowEvacuated");
        this.$store.dispatch("evacuees/updateData");
      } else {
        this.$store.dispatch("evacuees/disableShowEvacuated");
        this.$store.dispatch("evacuees/updateData");
      }
    },
    async openManEvacDialog(item) {
      const name = item.firstname + " " + item.lastname;
      await Swal.fire({
        title: name,
        text: "Sicher, dass die Person als evakuiert gemeldet werden soll?",
        showDenyButton: true,
        confirmButtonText: `Evakuieren`,
        denyButtonText: `Abbrechen`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.error = false;
          this.errorMessage = "";
          const payload = {
            evacuationtype: "manual",
            evacuationid: item.evacuationid,
            evacueeid: item.ID,
            evacueeident: "",
            submittinguserid: this.currentUser.user_id,
            submittingusername: this.currentUser.name,
          };

          this.$store
            .dispatch("evacuees/evacuateEvacuee", payload)
            .then(() => {
              this.error = false;
              this.errorMessage = "";
              succaudio.play();
              this.$store.dispatch("evacuees/updateData");
              this.$store.dispatch("evacuees/updateStatistics");
              this.evacueeident = "";
            })
            .catch((error) => {
              aerraudio.play();
              this.error = true;
              this.errorMessage = error.data.message;
              this.evacueeident = "";
            });
          this.$store.dispatch("evacuees/updateStatistics");
        } else if (result.isDenied) {
          return true;
        }
      });
    },
    convertTimeFormat(time) {
      const date = new Date(time);
      const timeString = date.toLocaleString("de-DE");
      return timeString;
    },
    initialize() {
      this.$store.dispatch("evacuees/disableShowEvacuated");
      this.$store.dispatch("evacuees/updateData");
      this.$store.dispatch("evacuees/updateStatistics");
      this.$store.dispatch("evacuees/updateLatestActiveEvacuationID");
    },
    updateList() {
      if (!this.timeractive || this.$route.name !== "LiveEvacuation") {
        this.setTimer(0);
        clearInterval(this.timer);
        this.timer = "";
        return;
      }
      this.$store.dispatch("evacuees/updateData");
      this.$store.dispatch("evacuees/updateStatistics");
      this.$store.dispatch("evacuees/updateLatestActiveEvacuationID");
    },
    setTimer(value) {
      if (value === 0) {
        clearInterval(this.timer);
      } else {
        clearInterval(this.timer);
        this.timer = setInterval(this.updateList, value * 1000);
      }
    },
    stopAutoUpdate() {
      clearInterval(this.timer);
    },
  },
};
</script>
